import { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import {
	HiOutlineChevronDoubleLeft,
	HiOutlineChevronDoubleUp,
} from 'react-icons/hi2';

import { useChat } from 'provider/ChatProvider';

export default function Floater(props) {
	const [open, setOpen] = useState(false);
	const { admission } = useChat();

	useEffect(() => {
		console.log(admission);
	}, [admission]);

	return (
		<>
			<Bar
				className="hidden sm:flex z-20"
				open={open}
				onOpenChange={(open) => setOpen(open)}
			>
				<MessageList data={admission} />
			</Bar>
			<Bar
				className="flex sm:hidden z-20"
				open={open}
				onOpenChange={(open) => setOpen(open)}
				vertical
			>
				<MessageList data={admission} />
			</Bar>
		</>
	);
}

function Bar(props) {
	return (
		<motion.div
			className={
				'fixed flex left-0 top-0 w-full sm:w-52 lg:w-64 h-auto max-h-full sm:h-full shadow-xl backdrop-blur-lg rounded-b-3xl sm:rounded-bl-none sm:rounded-r-3xl bg-white/50 ' +
				(props.className || '')
			}
			transition={{ ease: 'easeInOut', duration: 0.25 }}
			initial={props.open ? 'visible' : 'hidden'}
			animate={props.open ? 'visible' : 'hidden'}
			variants={
				props.vertical
					? { visible: { y: 0 }, hidden: { y: '-100%' } }
					: { visible: { x: 0 }, hidden: { x: '-100%' } }
			}
		>
			<div className="flex-1 relative w-full max-h-full">
				<Button
					open={props.open}
					onOpenChange={(open) => props.onOpenChange(open)}
					vertical={props.vertical}
				/>
				<div
					className="max-h-full h-full overflow-y-scroll pb-5 sm:pb-0"
					style={{ direction: 'rtl' }}
				>
					<div style={{ direction: 'ltr' }}>{props.children}</div>
				</div>
			</div>
		</motion.div>
	);
}

function Button(props) {
	return (
		<div className="absolute bottom-0 sm:right-0 w-full sm:w-0 h-0 sm:h-full flex items-center justify-center z-10">
			<motion.div
				className={
					'w-full sm:w-auto h-auto sm:h-full flex flex-col items-center justify-center backdrop-blur-sm sm:backdrop-blur-none ' +
					(props.open ? 'pt-2 sm:pt-0' : '')
				}
				initial={props.open ? 'visible' : 'hidden'}
				animate={props.open ? 'visible' : 'hidden'}
				variants={
					props.vertical
						? { visible: { y: '-50%' }, hidden: { y: '50%' } }
						: { visible: { x: 0 }, hidden: { x: '50%' } }
				}
			>
				<button
					className={
						'w-12 sm:w-5 h-6 sm:h-12 bg-gradient-to-r from-accent to-accent/80 sm:from-primary sm:to-secondary shadow-xl flex items-center justify-center ' +
						(props.open
							? 'rounded-t-xl sm:rounded-xl'
							: props.vertical
							? 'rounded-b-xl'
							: 'rounded-r-xl')
					}
					onClick={() => props.onOpenChange(!props.open)}
				>
					<div
						className={
							'text-white transition-all ' +
							(props.open ? 'rotate-0' : 'rotate-180')
						}
					>
						<HiOutlineChevronDoubleUp className="sm:hidden" />
						<HiOutlineChevronDoubleLeft className="hidden sm:block" />
					</div>
				</button>
			</motion.div>
		</div>
	);
}

function MessageList(props) {
	return (
		<div className="p-6 flex flex-col gap-3">
			{props.data && props.data.programme1 && (
				<Message label="Felvételi szak" value={props.data.programme1} />
			)}
			{props.data && props.data.programme2 && (
				<Message
					label="Osztatlan képzés esetén a 2. szak"
					value={props.data.programme2}
				/>
			)}
			{props.data && (
				<Message
					label="Csak gyakorlati vizsga van"
					value={props.data.practiceExamOnly ? 'Igen' : 'Nem'}
				/>
			)}
			{props.data && props.data.highSchoolSubjectGradesList && (
				<Message
					label="Tanulmányi pont"
					subLabel="Középiskolai tantárgyak"
				>
					<HighSchoolSubjectGradesList
						data={props.data.highSchoolSubjectGradesList}
					/>
				</Message>
			)}
			{props.data && props.data.graduationSubjectGradeList && (
				<Message
					label="Tanulmányi pont"
					subLabel="Érettségi eredmények"
				>
					<GraduationSubjectGradeList
						data={props.data.graduationSubjectGradeList}
					/>
				</Message>
			)}

			{props.data && props.data.graduationPointList ? (
				<Message label="Érettségi pont" subLabel="Eredmények">
					<GraduationPointList
						data={props.data.graduationPointList}
					/>
				</Message>
			) : null}
			{props.data && props.data.studyPointHighSchool ? (
				<Message
					label="Tanulmányi pont"
					subLabel="Tantárgy"
					value={props.data.studyPointHighSchool}
				/>
			) : null}
			{props.data && props.data.studyPointGraduation ? (
				<Message
					label="Tanulmányi pont"
					subLabel="Érettségi"
					value={props.data.studyPointGraduation}
				/>
			) : null}
			{props.data && props.data.graduationPoint ? (
				<Message
					label="Érettségi pont"
					value={props.data.graduationPoint}
				/>
			) : null}
			{props.data &&
			props.data.institutionalPointList &&
			Object.keys(props.data.institutionalPointList).length > 0 ? (
				<Message label="Intézményi pont">
					<InstitutionalPointList
						data={props.data.institutionalPointList}
					/>
				</Message>
			) : null}

			{props.data &&
				props.data.result &&
				props.data.result.map((item) => (
					<Message label="Eredmény" value={item} />
				))}
		</div>
	);
}

function Message(props) {
	return (
		<div className="bg-white rounded-xl shadow-xl text-xs flex flex-col p-2">
			<div>
				<div className="font-bold">{props.label}</div>
				{props.subLabel && (
					<div className="text-[10px] font-semibold">
						{props.subLabel}
					</div>
				)}
			</div>
			<div className="flex flex-col mt-1">
				{props.value && <div>{props.value}</div>}
				{props.children && <div>{props.children}</div>}
			</div>
		</div>
	);
}

function HighSchoolSubjectGradesList(props) {
	return (
		<div className="flex flex-col gap-1 text-[10px]">
			{props.data.map(
				(item, index) =>
					(item.yearEndGrade1 || item.yearEndGrade2) && (
						<div
							key={index}
							className="bg-secondary py-2 px-3 text-white rounded-md shadow-xl flex flex-row justify-between"
						>
							<div className="flex flex-col">
								<div className="font-bold capitalize">
									{item.name}
								</div>
								<div className="capitalize">Érdemjegy</div>
							</div>
							<div className="flex flex-col items-end">
								{item.yearEndGrade1 && (
									<div>
										Tavalyi év:{' '}
										<span className="font-bold">
											{item.yearEndGrade1}
										</span>
									</div>
								)}
								{item.yearEndGrade2 && (
									<div>
										Idei év:{' '}
										<span className="font-bold">
											{item.yearEndGrade2}
										</span>
									</div>
								)}
							</div>
						</div>
					)
			)}
		</div>
	);
}

function GraduationSubjectGradeList(props) {
	return (
		<div className="flex flex-col gap-1 text-[10px]">
			{props.data.map(
				(item, index) =>
					item.grade && (
						<div
							key={index}
							className="bg-secondary py-2 px-3 text-white rounded-md shadow-xl flex flex-row justify-between"
						>
							<div className="flex flex-col">
								<div className="font-bold capitalize">
									{item.name}
								</div>
							</div>
							<div className="flex flex-col items-end">
								{item.grade && (
									<div>
										<span className="font-bold">
											{item.grade}
										</span>
										%
									</div>
								)}
							</div>
						</div>
					)
			)}
		</div>
	);
}

function GraduationPointList(props) {
	return (
		<div className="flex flex-col gap-1 text-[10px]">
			{props.data.map(
				(item, index) =>
					item.grade && (
						<div
							key={index}
							className="bg-secondary py-2 px-3 text-white rounded-md shadow-xl flex flex-row"
						>
							<div className="flex-1">
								{item.graduationSubject && (
									<div>{item.graduationSubject.name}</div>
								)}
								{item.advLevel && (
									<div className="font-bold">
										Emelt szintű érettségi
									</div>
								)}
								{item.advLevelReq && (
									<div className="font-bold">
										Emelt szintű érettségi szükséges
									</div>
								)}
								{item.practiceExam && (
									<div className="font-bold">
										Gyakorlati vizsga
									</div>
								)}
							</div>

							<div className="flex flex-row justify-between">
								{item.grade && (
									<div>
										<span className="font-bold">
											{item.grade}
										</span>
										%
									</div>
								)}
							</div>
						</div>
					)
			)}
		</div>
	);
}

function InstitutionalPointList(props) {
	return (
		<div className="flex flex-col gap-1 text-[10px]">
			{Object.keys(props.data).map((category, index) => (
				<div
					key={index}
					className="bg-secondary py-2 px-3 text-white rounded-md shadow-xl flex flex-row"
				>
					<div className="flex-1">
						<div>{category}</div>
					</div>

					<div className="flex flex-row justify-between">
						<div>
							<span className="font-bold">
								{props.data[category]}
							</span>{' '}
							pont
						</div>
					</div>
				</div>
			))}
		</div>
	);
}
