import { useEffect, useRef, useState } from 'react';
import { HiPaperAirplane, HiMiniEllipsisVertical } from 'react-icons/hi2';
import { motion } from 'framer-motion';

import Toggle from 'elements/Toggle';

import { useChat } from 'provider/ChatProvider';
import MessageType from 'dto/MessageType';

export default function Chat(props) {
	const [sendOnEnter, setSendOnEnter] = useState(true);

	return (
		<div
			className={
				'w-full max-w-[960px] flex flex-col bg-white sm:rounded-[40px] shadow-2xl overflow-hidden ' +
				(props.className || '')
			}
		>
			<Header
				sendOnEnter={sendOnEnter}
				onSendOnEnterChange={() => setSendOnEnter(!sendOnEnter)}
			/>
			<MessageContainer />
			<Input sendOnEnter={sendOnEnter} />
		</div>
	);
}

function Header(props) {
	const [settingsOpen, setSettingsOpen] = useState(false);

	return (
		<div
			className="w-full pb-[40px]"
			style={{ backgroundColor: '#012851' }}
		>
			<div className="w-full h-full p-6 sm:p-8 flex flex-row gap-4 items-center justify-between text-white">
				<div className="flex-1 flex flex-row gap-3 items-center justify-between">
					<div
						className="h-7 sm:h-16 aspect-[1901/271] bg-contain bg-no-repeat"
						style={{
							backgroundImage:
								'url(/assets/images/elte_help_chatbot_desktop.svg)',
						}}
					></div>

					<div className="flex gap-1 items-center">
						<a
							href="https://creo.hu/"
							className="opacity-50 hover:opacity-75 transition pt-1"
						>
							<div
								className="h-6 aspect-[79/36] bg-contain bg-no-repeat"
								style={{
									backgroundImage:
										'url(/assets/images/creo-alt.png)',
								}}
							></div>
						</a>

						<button
							className="relative group ml-0 sm:ml-0 py-2 px-1 sm:p-2 rounded-xl hover:bg-accent hover:shadow-sm transition aspect-square h-full"
							onMouseEnter={() => setSettingsOpen(true)}
							onMouseLeave={() => setSettingsOpen(false)}
						>
							<HiMiniEllipsisVertical className="text-3xl group-hover:text-white transition" />

							<Settings open={settingsOpen}>
								<Setting
									label="Üzenet küldése Enter billentyűre"
									value={props.sendOnEnter}
									onClick={() => props.onSendOnEnterChange()}
								/>
							</Settings>
						</button>
					</div>
				</div>

				{/* <div className='flex flex-row items-end leading-none tracking-wide self-end'>
                    <span className='font-thin text-3xl opacity-100'>Chat</span>
                    <span className='font-bold text-3xl opacity-70'>Bot</span>
                </div> */}
			</div>
		</div>
	);
}

function Settings(props) {
	return (
		<motion.div
			className="absolute right-0 z-50"
			style={{ originX: 1, originY: 0.25 }}
			initial={props.open ? 'visible' : 'hidden'}
			animate={props.open ? 'visible' : 'hidden'}
			variants={{
				visible: { opacity: 1, y: 0, scale: 1, display: 'flex' },
				hidden: {
					opacity: 0,
					y: 0,
					scale: 0.5,
					transitionEnd: { display: 'none' },
				},
			}}
		>
			<div className="p-4 bg-accent/90 backdrop-blur rounded-xl shadow-2xl mt-4">
				{props.children}
			</div>
		</motion.div>
	);
}

function Setting(props) {
	return (
		<div className="flex gap-3 items-center" onClick={props.onClick}>
			<div className="text-xs whitespace-nowrap ">{props.label}</div>
			<Toggle checked={props.value} />
		</div>
	);
}

function MessageContainer(props) {
	const { messageList } = useChat();
	const chatRef = useRef(null);

	function scrollToBottom() {
		chatRef.current.scrollTop = chatRef.current.scrollHeight;
	}

	useEffect(() => {
		if (messageList) scrollToBottom();
	}, [messageList]);

	function isFirstMessageOfGroup(item, i) {
		return (
			item.messageType === MessageType.TEXT &&
			(i === 0 ||
				(messageList[i - 1] &&
					(messageList[i - 1].sender !== item.sender ||
						messageList[i - 1].messageType !== MessageType.TEXT)))
		);
	}
	function isLastMessageOfGroup(item, i) {
		return (
			item.messageType === MessageType.TEXT &&
			(i === messageList.length - 1 ||
				(messageList[i + 1] &&
					(messageList[i + 1].sender !== item.sender ||
						messageList[i + 1].messageType !== MessageType.TEXT)))
		);
	}

	return (
		<div className="w-full rounded-[40px] bg-neutral-100 shadow-2xl h-full overflow-hidden -my-[40px] z-10">
			<div
				className="w-full h-full p-8 flex flex-col gap-2 overflow-y-scroll overflow-x-hidden"
				ref={chatRef}
			>
				{messageList.map((data, i) => (
					<ChatMessageRender
						key={i}
						index={i}
						data={data}
						isFirstMessageOfGroup={isFirstMessageOfGroup(data, i)}
						isLastMessageOfGroup={isLastMessageOfGroup(data, i)}
						messageCount={messageList.length}
					/>
				))}
			</div>
		</div>
	);
}

function ChatMessageRender(props) {
	if (props.data.messageType === MessageType.TEXT)
		return <ChatTextMessage {...props} />;
	if (props.data.messageType === MessageType.LINK)
		return <ChatLinkMessage {...props} />;
	if (props.data.messageType === MessageType.OPTIONS)
		return <ChatOptionsMessage {...props} />;
	// if (props.data.messageType === MessageType.LEFT) return <ChatLeftMessage {...props} />;
	// if (props.data.messageType === MessageType.JOINED) return <ChatJoinedMessage {...props} />;
	return <></>;
}

function ChatTextMessage(props) {
	const { user } = useChat();

	const isOwn = () => {
		return props.data.sender === user.nickName;
	};

	return (
		<motion.div
			className="flex flex-col gap-2"
			style={{ originX: isOwn() ? 1 : 0, originY: 0.5 }}
			initial={{ scale: 0, x: isOwn() ? 50 : -50 }}
			animate={{ scale: 1, x: 0 }}
		>
			{props.isFirstMessageOfGroup && !isOwn() && (
				<div className="text-stone-400 text-[10px] px-2 font-bold">
					{props.data.sender}
				</div>
			)}

			<div
				className={
					'max-w-[75%] p-4 shadow-md rounded-2xl ' +
					(isOwn()
						? 'self-end bg-gradient-to-r from-primary to-secondary rounded-br-sm'
						: 'self-start bg-white rounded-bl-sm')
				}
			>
				<div
					className={
						'text-sm break-words ' +
						(props.data.sender === user.nickName
							? 'text-white'
							: '')
					}
					dangerouslySetInnerHTML={{ __html: props.data.message }}
				/>
			</div>

			{props.isLastMessageOfGroup && (
				<div
					className={
						'text-stone-400 text-[10px] px-2 py-1 ' +
						(isOwn() ? 'self-end' : 'self-start')
					}
				>
					{new Date(props.data.timestamp).toLocaleTimeString(
						undefined,
						{ year: 'numeric', month: 'short', day: 'numeric' }
					)}
				</div>
			)}
		</motion.div>
	);
}

function ChatLinkMessage(props) {
	const { user } = useChat();

	const isOwn = () => {
		return props.data.sender === user.nickName;
	};

	return (
		<motion.div
			className="flex flex-col gap-2"
			style={{ originX: isOwn() ? 1 : 0, originY: 0.5 }}
			initial={{ scale: 0, x: isOwn() ? 50 : -50 }}
			animate={{ scale: 1, x: 0 }}
		>
			{props.isFirstMessageOfGroup && !isOwn() && (
				<div className="text-stone-400 text-[10px] px-2 font-bold">
					{props.data.sender}
				</div>
			)}

			<div
				className={
					'max-w-[75%] p-4 shadow-md rounded-2xl ' +
					(isOwn()
						? 'self-end bg-gradient-to-r from-primary to-secondary rounded-br-sm'
						: 'self-start bg-white rounded-bl-sm')
				}
			>
				<div
					className={
						'text-sm ' +
						(props.data.sender === user.nickName
							? 'text-white'
							: '')
					}
				>
					<a
						href={props.data.message.url}
						target="_blank"
						rel="noreferrer"
						className="font-semibold text-blue-950 hover:text-blue-800"
					>
						{props.data.message.title}
					</a>
				</div>
			</div>

			{props.isLastMessageOfGroup && (
				<div
					className={
						'text-stone-400 text-[10px] px-2 py-1 ' +
						(isOwn() ? 'self-end' : 'self-start')
					}
				>
					{new Date(props.data.timestamp).toLocaleTimeString(
						undefined,
						{ year: 'numeric', month: 'short', day: 'numeric' }
					)}
				</div>
			)}
		</motion.div>
	);
}

function ChatOptionsMessage(props) {
	const { data, index, messageCount } = props;
	const options = data.message;
	const primaryOptions = options.filter(
		(opt) => opt.isPrimary && opt.value !== '0'
	);
	const secondaryOptions = options.filter(
		(opt) => !opt.isPrimary && opt.value !== '0'
	);
	const backOption = options.find((opt) => opt.value === '0');
	const { user, sendChosenOptionMessage } = useChat();
	const isLast = index + 1 === messageCount;
	const primaryFirst =
		primaryOptions.length > 0 && secondaryOptions.length > 0;

	const [value, setValue] = useState(null);
	const [showMore, setShowMore] = useState(null);

	const handleClick = (value) => {
		setValue(value);
		sendChosenOptionMessage(value);
	};

	const isOwn = () => {
		return props.data.sender === user.nickName;
	};

	return (
		<motion.div
			className="flex flex-row flex-wrap items-start gap-2 mb-2"
			style={{ originX: isOwn() ? 1 : 0, originY: 0.5 }}
			initial={{ scale: 0, x: isOwn() ? 50 : -50 }}
			animate={{ scale: 1, x: 0 }}
		>
			{primaryFirst && (
				<>
					{primaryOptions.map((option) => (
						<Button
							key={option.value}
							onClick={() => handleClick(option.value)}
							disabled={!isLast}
							selected={option.value === value}
							label={option.label}
						/>
					))}
					{showMore &&
						secondaryOptions.map((option) => (
							<Button
								key={option.value}
								onClick={() => handleClick(option.value)}
								disabled={!isLast}
								selected={option.value === value}
								label={option.label}
							/>
						))}
					{!showMore && (
						<Button
							onClick={() => setShowMore(true)}
							label="Többi"
						/>
					)}
					{backOption && (
						<Button
							onClick={() => handleClick(backOption.value)}
							disabled={!isLast}
							selected={backOption.value === value}
							label={backOption.label}
						/>
					)}
				</>
			)}
			{!primaryFirst &&
				options.map((option) => (
					<Button
						key={option.value}
						onClick={() => handleClick(option.value)}
						disabled={!isLast}
						selected={option.value === value}
						label={option.label}
					/>
				))}
		</motion.div>
	);
}

function Button(props) {
	return (
		<button
			className={
				'rounded-full px-5 py-3 transition-all ' +
				(props.disabled
					? props.selected
						? 'bg-gradient-to-r from-secondary to-secondary text-white'
						: 'border border-neutral-300 text-neutral-400'
					: 'bg-gradient-to-r from-primary to-secondary hover:to-primary text-white')
			}
			onClick={
				!props.disabled
					? props.onClick
					: console.log('Button disabled.')
			}
		>
			<div className="text-sm font-medium">{props.label}</div>
		</button>
	);
}

function Input(props) {
	const [messageText, setMessageText] = useState('');
	const { sendTextMessage, connected } = useChat();

	function onInputChange(ev) {
		setMessageText(ev.target.value);
	}

	function onMessageSubmit(ev) {
		// ev.preventDefault();
		if (messageText !== '') {
			sendTextMessage(messageText);
			setMessageText('');
		}
	}

	const handleKeyDown = (event) => {
		if (event.key === 'Enter' && props.sendOnEnter) {
			onMessageSubmit();
		}
	};

	return (
		<div className="w-full bg-gradient-to-r from-primary/90 to-secondary/80 pt-[40px]">
			<div className="w-full h-full flex p-6">
				<div className="w-full flex flex-row items-center gap-2 p-1 bg-white/20 rounded-full ring-1 ring-white/70 focus-within:ring-2 focus-within:ring-white/75 transition">
					<input
						onChange={onInputChange}
						onKeyDown={handleKeyDown}
						value={messageText}
						className="w-full px-5 py-2.5 outline-none bg-transparent text-white font-medium placeholder:font-light placeholder:text-white/50 "
						placeholder="Kérjük, írd ide az üzeneted"
						disabled={!connected}
					/>

					<button
						className="group aspect-square h-full bg-white hover:bg-accent rounded-full shadow-lg flex items-center justify-center transition"
						onClick={() => onMessageSubmit()}
					>
						<HiPaperAirplane className="text-2xl pl-0.5 text-primary group-hover:text-white transition" />
					</button>
				</div>
			</div>
		</div>
	);
}
