import Chat from 'components/Chat';
import ChatProvider from 'provider/ChatProvider';
import Floater from 'components/Floater';

export default function App() {
  return (
    <ChatProvider>

      <div className='relative w-full h-screen bg-white flex items-center justify-center p-0 sm:p-8 xl:p-16' >

        <div className='absolute w-full h-full max-h-screen top-0 left-0 bg-cover grayscale-[0.9] opacity-50 blur-sm ' style={{ backgroundImage: 'url(/assets/images/background.jpg)' }} />

        <Floater className='z-20'/>

        <Chat className='z-10 self-stretch' />

      </div>

    </ChatProvider>
  );
}