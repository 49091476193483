const MessageType = {
    WHOAMI: 'WHOAMI',
    VISITORINFO: 'VISITORINFO',
	JOINED: 'JOINED',
	LEFT: 'LEFT',
	TEXT: 'TEXT',
	OPTIONS: 'OPTIONS',
	CHOSENOPTION: 'CHOSENOPTION',
	IMAGE: 'IMAGE',
	LINK: 'LINK'
};

export default MessageType;
